import Vue from 'vue'
import admin from './admin'
import gestor from './gestor'
import tecnico from './tecnico'
import produtor from './produtor'
import supervisor from './supervisor'

export default () => {
  switch (Vue.prototype.$user.get().role) {
    case 'admin':
      return admin
    case 'gestor':
      return gestor
    case 'tecnico':
      return tecnico
    case 'produtor':
      return produtor
    case 'supervisor':
      return supervisor
    default:
      return []
  }
}

export default [
  {
    icon: 'mdi-monitor-dashboard',
    title: 'Início',
    to: '/tecnico/dashboard',
  },
  {
    icon: 'mdi-shopping',
    title: 'Loja',
    to: '/tecnico/loja/produtos',
    group: '/tecnico/loja',
  },
  {
    icon: 'mdi-cart',
    title: 'Central de Vendas',
    group: '/tecnico/cdv',
    children: [
      {
        icon: 'mdi-clipboard-clock-outline',
        title: 'Acompanhar Pedidos',
        to: 'pedidos',
      },
      {
        icon: 'mdi-clipboard-file-outline',
        title: 'Acompanhar Cotações',
        to: 'cotacoes',
      },
    ],
  },
  {
    icon: 'mdi-account-details',
    title: 'Pessoas',
    group: '/tecnico',
    children: [
      {
        icon: 'mdi-account-cowboy-hat',
        title: 'Produtores',
        to: 'produtores',
      },
      {
        icon: 'mdi-tractor',
        title: 'Fazendas',
        to: 'fazendas',
      },
    ],
  },
]

export default [
  {
    icon: 'mdi-monitor-dashboard',
    title: 'Início',
    to: '/produtor/dashboard',
  },
  {
    icon: 'mdi-shopping',
    title: 'Loja',
    to: '/produtor/loja/produtos',
    group: '/produtor/loja',
  },
  {
    icon: 'mdi-cart',
    title: 'Central de Compras',
    group: '/produtor/cdv',
    children: [
      {
        icon: 'mdi-clipboard-clock-outline',
        title: 'Acompanhar Pedidos',
        to: 'pedidos',
      },
      {
        icon: 'mdi-clipboard-file-outline',
        title: 'Acompanhar Cotações',
        to: 'cotacoes',
      },
    ],
  },
]

<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    dark
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    hide-overlay
    mobile-breakpoint="960"
    app
    :width="$vuetify.breakpoint.xlOnly ? '270' : '215'"
    v-bind="$attrs"
    src="@/assets/sidebar-1.png"
    elevation="12"
  >
    <template v-slot:img="props">
      <v-img
        v-bind="props"
      />
    </template>
    <v-row
      class="mx-4 py-4"
      align="center"
    >
      <v-avatar color="teal">
        <v-img
          v-if="profile.avatar"
          :src="profile.avatar"
        />
        <span
          v-else
          class="white--text headline"
        >{{ profile.initials }}</span>
      </v-avatar>
      <v-col class="pb-1">
        <div style="color: white; font-size: 12px">
          {{ 'Bem-vindo, ' + profile.nome }}
        </div>
        <div style="color: white; font-size: 12px">
          {{ profile.description }}
        </div>
      </v-col>
    </v-row>
    <v-divider class="mb-2" />
    <v-list
      style="height: calc(100vh - 180px); overflow: auto"
      nav
      class="mb-6"
      dense
    >
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        />
        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
    </v-list>
    <v-divider class="my-1 mb-2" />
    <v-row class="ma-3 mb-0">
      <v-img
        class="sombra"
        src="@/assets/logo_branca.png"
        max-height="70"
        contain
      />
    </v-row>
  </v-navigation-drawer>
</template>

<script>
// Utilities
  import { mapState } from 'vuex'
  import { logout } from '@/services/AuthService'
  import menus from '@/menus'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: menus(),
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items
      },
      profile () {
        const { avatar, nome, description, role } = this.$user.get()

        if (role === 'guest') {
          return {}
        }

        const names = nome.split(' ')
        const initials =
          names.length > 1
            ? `${(names[0][0] + names[names.length - 1][0]).toUpperCase()}`
            : names[0].substring(0, 2).toUpperCase()

        return {
          avatar,
          initials,
          nome: names[0],
          description,
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      logout,
    },
  }
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

.v-navigation-drawer .v-icon.v-icon
    font-size: 20px !important

#core-navigation-drawer

  .v-list-group__items .v-list-item
    margin-left: 15px

  .v-list-item__icon--text, .v-list-item__icon:first-child, .v-list-item__icon--text, .v-list-item__icon:first-child
    margin-right:  7px !important

  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon
    min-width: 21px

  .v-list-item--active
    background: linear-gradient(to right, RGB(86, 127, 231, 1), RGB(86, 127, 231, 0.08))
    opacity: 1 !important

  .v-list-item--active.active
   color: #ffffff !important

  .v-list--nav
    padding-left: 6px !important
    padding-right: 6px!important

  .sombra
    -webkit-filter: drop-shadow(3px 3px 3px #535353)
    filter: drop-shadow(3px 3px 3px #535353)

  .v-list-item--focus
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 16px
        margin-left: 0px !important

      +rtl()
        margin-left: 4px
        margin-right: 16px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 7px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px

      .v-list-group__header__append-icon
          max-width: 21px

</style>

import { relatorios } from '../utils/constants'

export default [
  {
    icon: 'mdi-monitor-dashboard',
    title: 'Início',
    to: '/supervisor/dashboard',
  },
  {
    icon: 'mdi-cart',
    title: 'Central de Vendas',
    group: '/supervisor/cdv',
    children: [
      {
        icon: 'mdi-clipboard-clock-outline',
        title: 'Acompanhar Pedidos',
        to: 'pedidos',
      },
      {
        icon: 'mdi-clipboard-file-outline',
        title: 'Acompanhar Cotações',
        to: 'cotacoes',
      },

    ],
  },
  {
    icon: 'mdi-pail',
    title: 'Central do Leite',
    group: '/gestor/cdl',
    children: [
      {
        icon: 'mdi-pail',
        title: 'Volume de Leite',
        to: 'volumes',
      },
      {
        icon: 'mdi-flask-outline',
        title: 'Qualidade do Leite',
        to: 'qualidades',
      },
    ],
  },
  {
    icon: 'mdi-bullhorn-outline',
    title: 'Comunicações',
    group: '/supervisor/ccc',
    children: [
      {
        icon: 'mdi-clipboard-file-outline',
        title: 'Mensagens Produtor',
        to: 'notificacoesProdutor/?tipo=P',
        permission: 'list_notificao_produtor',
      },
      {
        icon: 'mdi-clipboard-file-outline',
        title: 'Mensagens Técnicos',
        to: 'notificacoesTecnico/?tipo=T',
        permission: 'list_notificao_tecnico',
      },
    ],
  },
  {
    icon: 'mdi-package-variant-closed',
    title: 'Produtos',
    group: '/supervisor/cdc',
    children: [
      {
        icon: 'mdi-book-open-page-variant-outline',
        title: 'Catálogos',
        to: 'catalogos',
      },
      {
        icon: 'mdi-package-variant-closed',
        title: 'Produtos',
        to: 'produtos',
      },
      {
        icon: 'mdi-ticket-percent-outline',
        title: 'Promoções',
        to: 'ofertas',
        permission: 'list_ofertas',
      },
      {
        icon: 'mdi-tag-outline',
        title: 'Vouchers',
        to: 'voucher',
        permission: 'catalogos',
      },
    ],
  },
  {
    icon: 'mdi-account-details',
    title: 'Pessoas',
    group: '/supervisor',
    children: [
      {
        icon: 'mdi-account-check',
        title: 'Solicitações de Conta',
        to: 'cadastros',
      },
      {
        icon: 'mdi-account-cowboy-hat',
        title: 'Produtores',
        to: 'produtores',
      },
      {
        icon: 'mdi-barn',
        title: 'Fazendas',
        to: 'fazendas',
      },
      {
        icon: 'mdi-account-hard-hat',
        title: 'Técnicos',
        to: 'tecnicos',
      },
      {
        icon: 'mdi-account-supervisor',
        title: 'Fornecedores',
        to: 'fornecedores',
      },
    ],
  },
  {
    icon: 'mdi-note-text',
    title: 'Relatórios',
    group: '/supervisor/relatorio',
    children: [
      {
        icon: 'mdi-circle-medium',
        title: 'Acompanhamento Semanal de',
        subtitle: 'Vendas',
        to: relatorios.acompanhamentoSemanalVendas,
      },
      {
        icon: 'mdi-circle-medium',
        title: 'Vendas por Unidades de Negócios',
        to: relatorios.vendasUnidadesNegocio,
      },
      {
        icon: 'mdi-circle-medium',
        title: 'Vendas por Técnicos',
        to: relatorios.vendasTecnicos,
      },
      {
        icon: 'mdi-circle-medium',
        title: 'Vendas por Produtores',
        to: relatorios.vendasProdutores,
      },
      {
        icon: 'mdi-circle-medium',
        title: 'Desempenho de Promoções',
        to: relatorios.desempenhoPromocoes,
      },
      {
        icon: 'mdi-circle-medium',
        title: 'Produtos mais Vendidos',
        to: relatorios.produtosMaisVendidos,
      },
      {
        icon: 'mdi-circle-medium',
        title: 'Produtores sem compra no período',
        to: relatorios.produtoresSemCompra,
      },
      {
        icon: 'mdi-circle-medium',
        title: 'Fazendas por Produtor',
        to: relatorios.produtoresFazendas,
      },
    ],
  },
]
